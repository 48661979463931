.textMarker {
  white-space: nowrap;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  /*border-style: solid;*/
  font-size: 20px;
  font-weight: bold;
  height: 80px;
  width: 80px;
  /*-webkit-text-stroke: 1px black; !* width and color *!*/
  /*background: rgba(255, 255, 255, 0.5);*/
}

.weatherStation {
  margin-top: 30px;
}

.tempLabel {
  white-space: nowrap;
}

.bomberos {
  font-size: 20px;
  font-weight: bold;
}

.comunidadName {
  white-space: nowrap;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  /*border-style: solid;*/
  font-size: 16px;
  font-weight: bold;
  width: 60px;
  height: 60px;
  color: rgba(0, 0, 0, 0.5);
  /*background: rgba(255, 255, 255, 0.2);*/
}
